var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5 wrapper p-5"},[_vm._m(0),_c('BaseTitle',{attrs:{"title":"orders"}}),(_vm.isloading)?_c('div',[_vm._v("Loading data please wait...")]):_vm._e(),(!_vm.isloading && !_vm.items.length)?_c('div',[_vm._v("No Orders Found")]):_vm._e(),_c('b-table',{attrs:{"items":_vm.orders,"striped":"","borderless":"","responsive":"sm","small":""},scopedSlots:_vm._u([{key:"cell(orderId)",fn:function(data){return [_c('router-link',{attrs:{"to":{
          name: 'supplementSummary',
          params: {
            report: data.item.reportId,
            id: data.item.orderId
          }
        }}},[_vm._v(_vm._s(data.item.orderId))])]}},{key:"cell(Report)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.reportId)+" ")]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"text-capitalize",class:{
          'text-success': data.item.status.toLowerCase() === 'completed'
        }},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}}])})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float-right",staticStyle:{"position":"absolute","top":"80px","right":"30px"}},[_c('a',{attrs:{"href":"/seeemail","target":"_blank"}},[_vm._v("Read Last Email")])])}]

export { render, staticRenderFns }