<template>
  <div class="mt-5 wrapper p-5">
    <div class="float-right" style="position: absolute; top: 80px; right: 30px">
      <a href="/seeemail" target="_blank">Read Last Email</a>
    </div>
    <BaseTitle title="orders" />
    <div v-if="isloading">Loading data please wait...</div>
    <div v-if="!isloading && !items.length">No Orders Found</div>

    <b-table :items="orders" striped borderless responsive="sm" small>
      <template #cell(orderId)="data">
        <router-link
          :to="{
            name: 'supplementSummary',
            params: {
              report: data.item.reportId,
              id: data.item.orderId
            }
          }"
          >{{ data.item.orderId }}</router-link
        >
      </template>
      <template #cell(Report)="data">
        {{ data.item.reportId }}
      </template>
      <template #cell(status)="data">
        <span
          :class="{
            'text-success': data.item.status.toLowerCase() === 'completed'
          }"
          class="text-capitalize"
        >
          {{ data.item.status }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import { apiService } from '../../scripts/services'
import BaseTitle from '../Base/BaseTitle.vue'
export default {
  components: { BaseTitle },
  data() {
    return {
      items: [],
      isloading: false
    }
  },
  mounted() {
    this.isloading = true
    apiService.getOrders().then((r) => {
      this.isloading = false
      this.items = r
    })
  },
  computed: {
    orders() {
      const dk = this.items.map((x) => {
        const b = Object.assign(
          {
            orderId: x.orderId,
            reportId: x.report.reportId,
            loanNumber: x.report.loanNumber,
            borrower: x.report.borrower,
            coBorrower: x.report.coBorrower,
            product: x.report.product,
            bureau: x.report.bureau
          },
          {
            dateOrdered: x.created,
            status: x.status
          }
        )
        return b
      })
      return dk
    }
  },
  methods: {}
}
</script>
